<template>
  <div class="pms-performance-review-month">
    <progress-bar
      :label="date"
      :value="total" />

    <div
      v-for="warehouse in monthReview.reviews"
      :key="warehouse.warehouse.id"
      class="pms-performance-review-warehouse"
      :class="isReviewDoneClass(warehouse.reviewState)"
      @click="toggleReview(warehouse)">
      <span class="warehouseName">
        {{ warehouse.warehouse.name }}
      </span>
      <div
        v-if="isReviewDone(warehouse.reviewState)"
        class="reviewState pms-done">
        {{ warehouse.totalScore }} / {{ maximumScore }}
      </div>
      <div
        v-else
        class="reviewState">
        {{ isReviewDoing(warehouse.reviewState, warehouse.reviews.length) }}
      </div>
    </div>
  </div>
</template>

<script>
import MockupChoices from '@/assets/json/Mockup/MockupChoices.json'
import ProgressBar from './ProgressBar.vue'

export default {
  components: {
    ProgressBar
  },
  props: {
    review: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      date: '',
      total: 0,
      maximumScore: 0
    }
  },
  computed: {
    monthReview () {
      this.initReview()
      return this.review
    }
  },
  methods: {
    initReview () {
      const month = this.$dayjs().month(this.review.month.month - 1).format('MMMM')
      this.date = `${month} ${this.review.month.year}`
      const saveCount = this.review.reviews.reduce((sum, warehouse) => (
        (warehouse.reviewState === 'done') ? sum + 1 : sum + 0
      ), 0)
      this.total = (this.review.reviews.length) ? Math.round((saveCount / this.review.reviews.length) * 100) : 0
      this.maximumScore = MockupChoices.reduce((sum, category) => {
        const score = category.list.reduce((totalScore, choice) => totalScore + choice.options[0].score, 0)

        return sum + score
      }, 0)
    },
    toggleReview (warehouse) {
      const tmpWarehouse = warehouse
      tmpWarehouse.date = this.review.month
      this.$emit('toggle-review', { isReview: true, warehouse: tmpWarehouse })
    },
    isReviewDoneClass (state) {
      return (state === 'done') ? 'pms-done' : ''
    },
    isReviewDone (state) {
      return state === 'done'
    },
    isReviewDoing (state, length) {
      return (state === 'draft' && length) ? 'ทำต่อ' : 'ให้คะแนน'
    }
  }
}
</script>

<style scoped>
.pms-performance-review-month {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}
.pms-performance-review-warehouse {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e9e9e9;
  border-radius: 10px;

  padding: 10px;
  margin: 10px 0;
  width: 100%;
  height: 40px;
}
.pms-performance-review-warehouse:hover {
  cursor: pointer;
}
.pms-done {
  background-color: #8de38b !important;
}
.pms-performance-review-warehouse > .warehouseName {
  flex: 0.8;
}
.pms-performance-review-warehouse > .reviewState {
  align-self: center;
  text-align: center;
  font-size: 12px;
  background-color: #c4c4c4;
  border-radius: 20px;
  flex: 0.2;
  max-width: 80px;
  height: 100%;
}
</style>
