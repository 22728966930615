<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    :persistent="loading">
    <v-card>
      <v-card-title>
        Branch Management
      </v-card-title>
      <v-card-text v-if="!loading">
        <div class="warehouses-list pa-2">
          <div
            v-for="(warehouse, i) in warehouses"
            :key="i"
            class="warehouse">
            <v-checkbox
              v-model="warehouse.check"
              :label="warehouse.name"
              hide-details
              color="secondary"
              class="mt-0 pb-1" />
          </div>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <v-progress-circular
          class="overlay-progress"
          :size="70"
          :width="7"
          color="black"
          indeterminate />
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <button
          class="saveButton rounded"
          :disabled="loading"
          @click="saveWarehouses()">
          Save
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import WarehouseProvider from '@/resources/WarehouseProvider'
import ReviewWarehousesProvider from '@/resources/ReviewWarehouses'
import { mapActions } from 'vuex'

const WarehouseService = new WarehouseProvider()
const ReviewWarehousesService = new ReviewWarehousesProvider()

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      warehouses: []
    }
  },
  computed: {
    dialog: {
      get () {
        if (this.value) {
          this.getWarehouses()
        }

        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async getWarehouses () {
      try {
        this.loading = true

        const { data } = await WarehouseService.getWarehouse()
        const { data: reviewWarehouses } = await ReviewWarehousesService.getReviewWarehouses()

        this.warehouses = data.map((w) => {
          const found = reviewWarehouses.find((r) => r.warehouse.id === w.id)

          return {
            ...w,
            check: Boolean(found)
          }
        })
      } catch (error) {
        console.error('getWarehouses', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async saveWarehouses () {
      try {
        this.loading = true

        const formData = this.warehouses.filter((w) => w.check).map((w) => ({
          id: w.id,
          name: w.name,
          code: w.code
        }))
        await ReviewWarehousesService.createManyReviewWarehouses({
          warehouses: formData
        })
        this.setSnackbar({
          value: true,
          message: 'Saved.',
          type: 'success'
        })
        this.dialog = false
      } catch (error) {
        console.error('saveWarehouses', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.saveButton {
  background-color: #000;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 15px;
  min-width: 100px;
}
.saveButton:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}
.warehouses-list {
  width: 100%;
  height: 500px;
  overflow-y: auto;
}
.warehouse {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  transition-duration: .3s;
}
.warehouse:hover {
  background-color: whitesmoke;
  cursor: pointer;
}
.overlay-progress {
  margin-top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
