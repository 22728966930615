<template>
  <div class="pms-performance-report-progress-bar">
    <div class="label-container">
      <span class="label">{{ label }}</span>
      <span class="percent">{{ value }}%</span>
    </div>
    <v-progress-linear
      background-color="#c4c4c4"
      color="#8de38b"
      :value="value" />
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>
.pms-performance-report-progress-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.label-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.label-container > .label {
  flex: 0.9;
}
.label-container > .percent {
  flex: 0.1;
  text-align: right;
}
</style>
