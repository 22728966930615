<template>
  <v-container
    class="pms-performance-review-container"
    fluid>
    <v-row justify="space-between">
      <v-col cols="auto">
        <h2>Performance Review</h2>
      </v-col>
      <v-col
        v-if="!isReview"
        cols="auto">
        <button
          class="manageButton rounded"
          @click="showManageWarehouses()">
          จัดการสาขา
        </button>
        <!-- <button
          class="manageButton rounded"
          @click="exportChoices()">
          Export
        </button> -->
      </v-col>
    </v-row>

    <div
      v-if="!loading"
      class="pms-performance-review-month-list">
      <div v-if="!isReview">
        <month-list
          v-for="(review, index) in fetchReviews"
          :key="index"
          :review="review"
          @toggle-review="toggleReview($event)" />

        <div class="page-button">
          <v-btn
            class="mx-1"
            outlined
            :disabled="!(pageNumber - 1)"
            @click="changePage(-1)">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-text-field
            v-model="pageNumber"
            class="page-input"
            outlined
            hide-details
            dense
            disabled />
          <v-btn
            class="mx-1"
            outlined
            @click="changePage(1)">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>

      <review
        v-else
        :review-warehouse="reviewWarehouse"
        @save="saveReview($event)"
        @cancel="cancelReview()" />
    </div>

    <v-progress-circular
      v-else
      class="overlay-progress"
      :size="70"
      :width="7"
      color="black"
      indeterminate />

    <manage-review-warehouses v-model="isShowManage" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import PerformanceReviewProvider from '@/resources/PerformanceReviewProvider'
import ReviewWarehousesProvider from '@/resources/ReviewWarehouses'
import MockupChoices from '@/assets/json/Mockup/MockupChoices.json'
import MonthList from '../components/MonthList.vue'
import Review from '../components/Review.vue'
import ManageReviewWarehouses from '../components/ManageReviewWarehouses.vue'

const PerformanceReviewService = new PerformanceReviewProvider()
const ReviewWarehousesService = new ReviewWarehousesProvider()

export default {
  components: {
    MonthList,
    Review,
    ManageReviewWarehouses
  },
  data () {
    return {
      pageNumber: 1,
      amountPerPage: 6,
      reviewWarehouse: null,
      isReview: false,
      isShowManage: false,
      fetchReviews: [],
      loading: false
    }
  },
  watch: {
    isShowManage () {
      if (!this.isShowManage) {
        this.getReviews(this.pageNumber)
      }
    }
  },
  created () {
    this.getReviews(1)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async getReviews (page) {
      try {
        this.loading = true

        let tmpMonth = (+this.$dayjs().format('M'))
        let tmpYear = (+this.$dayjs().format('YYYY'))
        tmpMonth -= (this.amountPerPage * (page - 1))

        if (tmpMonth < 1) {
          tmpMonth = Math.abs(tmpMonth)
          const diff = Math.floor((tmpMonth + 12) / 12)
          if (diff > 0) {
            tmpYear -= diff
            tmpMonth = Math.abs(tmpMonth - (diff * 12))
          }
        }

        const end = this.$dayjs().month(tmpMonth - 1).year(tmpYear)
        const start = end.subtract(5, 'month')
        const query = {
          start: start.format('M-YYYY'),
          end: end.format('M-YYYY')
        }

        let { data: performanceResponse } = await PerformanceReviewService.getPerformanceReviewGroupByMonth(query)
        const { data: reviewWarehousesResponse } = await ReviewWarehousesService.getReviewWarehouses()
        performanceResponse = performanceResponse.filter((item) => item.month !== null)
        performanceResponse.forEach((month) => {
          const tmpMonthReviews = month
          const mockup = []
          reviewWarehousesResponse.forEach((w) => {
            const found = tmpMonthReviews.reviews.find((review) => review.warehouse.id === w.warehouse.id)

            if (!found) {
              mockup.push({
                month: month.month,
                reviewState: 'draft',
                reviews: [],
                status: 'active',
                totalScore: 0,
                warehouse: w.warehouse
              })
            }
          })
          tmpMonthReviews.reviews = [...tmpMonthReviews.reviews, ...mockup]
        })

        this.fetchReviews = performanceResponse
      } catch (error) {
        console.error(error.message)
        this.setSnackbar({
          value: true,
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    changePage (offset) {
      this.pageNumber += offset
      this.getReviews(this.pageNumber)
    },
    toggleReview (review) {
      this.isReview = review.isReview
      this.reviewWarehouse = review.warehouse
    },
    async saveReview (saveData) {
      try {
        this.loading = true

        const { reviewForm, isNew } = saveData

        if (isNew) {
          await PerformanceReviewService.createPerformanceReview(reviewForm)
        } else {
          await PerformanceReviewService.updatePerformanceReview(reviewForm)
        }

        this.setSnackbar({
          value: true,
          message: 'Save review success.',
          type: 'success'
        })

        this.getReviews(this.pageNumber)
      } catch (error) {
        console.error(error.message)
        this.setSnackbar({
          value: true,
          message: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.toggleReview({
          isReview: false,
          warehouse: null
        })
      }
    },
    cancelReview () {
      this.toggleReview({
        isReview: false,
        warehouse: null
      })
    },
    showManageWarehouses () {
      this.isShowManage = true
    },
    exportChoices () {
      const options = {
        filename: 'Choices',
        showLabels: false,
        useKeysAsHeaders: true
      }

      const mappedChoices = MockupChoices.reduce((arr, choice) => {
        const tmpArr = [...arr]

        const lists = choice.list.map((item) => ({
          category: choice.category,
          question: item.label,
          options: item.options.map((opt) => `${opt.key}=${opt.score}`).join(', ')
        }))

        return [...tmpArr, ...lists]
      }, [])

      const choices = []
      const groupped = this.groupByKeys(mappedChoices, ['category'])

      for (const key of Object.keys(groupped)) {
        const payload = [
          {
            คำถาม: key,
            คะแนน: ''
          },
          ...groupped[key].map((choice) => ({
            คำถาม: choice.question,
            คะแนน: choice.options
          }))
        ]

        choices.push(...payload)
      }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(choices)
    },
    groupByKeys (items, by) {
      const result = {}

      for (const item of items) {
        const key = by.map((prop) => item[prop]).join(';')

        if (result[key]) {
          result[key].push(item)
        } else {
          result[key] = [item]
        }
      }

      return result
    }
  }
}
</script>

<style scoped>
.manageButton {
  background-color: #000;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 15px;
  min-width: 100px;
}
.pms-performance-review-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 12px;
}
.pms-performance-review-month-list {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 30px auto;
}
.overlay-progress {
  margin-top: 50px;
  left: 50%;
  transform: translateX(-50%);
}
.page-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.page-button > button {
  height: 40px !important;
}
.page-input {
  max-width: 150px;
}
</style>
