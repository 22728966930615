<template>
  <div class="pms-performance-review-review-container">
    <div class="warehouse">
      <span class="warehouseName">
        {{ reviewWarehouse.warehouse.name }}
      </span>
      <div class="reviewDate">
        {{ reviewDate }}
      </div>
    </div>

    <v-expansion-panels focusable>
      <v-expansion-panel
        v-for="(choice, i) in choices"
        :key="i">
        <v-expansion-panel-header>
          {{ `${choice.category} (${choice.score}/${choice.totalScore})` }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            v-for="(item, n) in choice.list"
            :key="n">
            <v-col class="label mt-4">
              {{ item.label }}
            </v-col>
            <v-col>
              <v-radio-group
                v-model="item.selected"
                row
                hide-details>
                <v-radio
                  v-for="(option, op) in item.options"
                  :key="op"
                  :label="option.key"
                  :value="option.value"
                  color="secondary" />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <div class="saveButton-container">
      <button
        class="saveButton mt-2"
        @click="saveReview()">
        Save
      </button>
      <button
        class="cancelButton mt-2 ml-2"
        @click="cancelReview()">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import MockupChoices from '@/assets/json/Mockup/MockupChoices.json'

export default {
  props: {
    reviewWarehouse: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      reviewDate: ''
    }
  },
  computed: {
    choices () {
      this.initReview()

      const tmpChoice = MockupChoices.map((item) => {
        const tmpItem = item
        const warehouseChoices = this.reviewWarehouse?.reviews?.filter(((choice) => item.category === choice.category)) || []

        tmpItem.list = item.list.map((choice) => {
          const tmpItemChoice = choice
          const matchChoice = warehouseChoices.find((w) => w.label === choice.label)

          if (matchChoice) {
            const foundItemChoice = tmpItemChoice.options.find((option) => option.value === matchChoice.choosen)
            tmpItemChoice.selected = foundItemChoice.value
            tmpItemChoice.score = foundItemChoice.score
          } else {
            tmpItemChoice.selected = -1
            tmpItemChoice.score = 0
          }

          let maxScore = tmpItemChoice?.options[0]?.score || 0

          tmpItemChoice.options.forEach((option) => {
            if (option.score > maxScore) {
              maxScore = option.score
            }
          })

          return {
            ...tmpItemChoice,
            maxScore
          }
        })

        return {
          ...tmpItem,
          score: tmpItem.list.reduce((sum, c) => sum + c.score, 0),
          totalScore: tmpItem.list.reduce((sum, c) => sum + c.maxScore, 0)
        }
      })

      return tmpChoice
    }
  },
  methods: {
    initReview () {
      const month = this.$dayjs().month(this.reviewWarehouse.month.month - 1).format('MMMM')
      this.reviewDate = `${month} ${this.reviewWarehouse.month.year}`

      MockupChoices.forEach((choice) => {
        choice.list.forEach((item) => {
          const tmpItem = item
          tmpItem.selected = -1
        })
      })
    },
    saveReview () {
      const tmpChoices = JSON.parse(JSON.stringify(this.choices))
      const choicesForm = []
      let done = true

      tmpChoices.forEach((choice) => {
        choice.list.forEach((item) => {
          const option = item.options.find((op) => op.value === item.selected)

          if (item.selected !== -1) {
            choicesForm.push({
              category: choice.category,
              label: item.label,
              choosen: item.selected,
              score: (option) ? option.score : 0
            })
          } else {
            done = false
          }
        })
      })

      const reviewForm = {
        id: this.reviewWarehouse.id,
        month: this.reviewWarehouse.month,
        reviewState: (done) ? 'done' : 'draft',
        reviews: choicesForm,
        warehouse: this.reviewWarehouse.warehouse
      }

      let isNew = true
      if (reviewForm.id) {
        isNew = false
      }

      this.$emit('save', { reviewForm, isNew })
    },
    cancelReview () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>
.pms-performance-review-review-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
}
.pms-performance-review-review-container > .warehouse {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #e9e9e9;
  border-radius: 10px;

  padding: 10px;
  margin: 10px 0;
  width: 100%;
  height: 40px;
}
.warehouse > .warehouseName {
  flex: 0.6;
}
.warehouse > .reviewDate {
  align-self: center;
  text-align: center;
  text-align: right;
  border-radius: 20px;
  flex: 0.4;
  max-width: 150px;
  height: 100%;
}
.label {
  align-self: center;
}
.saveButton-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.saveButton-container > .saveButton {
  background-color: #000;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 15px;
  min-width: 100px;
}
.saveButton-container > .cancelButton {
  color: #000;
  border: 1px solid;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 15px;
  min-width: 100px;
}
</style>
